import { cn } from '@bem-react/classname';
import React from 'react';
import './promo-block.scss';

/**
 *
 * @param {Object} props
 * @param {string} props.price
 * @param {Array.<{contents: string, amount: number}>} props.items
 *
 * @returns
 */
const PromoBlock = ({ items = [] }) => {
    const promoBlockClass = cn('PromoBlock');
    return <div className={promoBlockClass()}></div>
}

export default PromoBlock;
