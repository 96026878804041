import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import React from "react";
import Breadcrumbs from "../components/Breadcrumbs";
import PromoBlock from "../components/PromoBlock";
import { ContentFlow, ScreenSize } from "../landingnewcomponents/Flow";
import Paragraph from "../landingnewcomponents/Paragraph/Landing";
import Section, { SECTION_COLOR } from "../landingnewcomponents/Section/Landing";
import SubTitle from "../landingnewcomponents/SubTitle/Landing";
import Title, { TITLE_COLORS } from "../landingnewcomponents/Title/Landing";
import Layout from "../layouts/layout";
import ContactUsSection from "../sections/contact-us";
import TutorialsSection from "../sections/tutorials";
import "./../common/styles.scss";
import { graphql, useStaticQuery } from "gatsby";
import { normalizePost } from "../helpers/normalizePost";


const TutorialsPage = ({ location }) => {
  const { formatMessage, locale } = useIntl();

  const inspiredPostsFromWordpressRaw = useStaticQuery(graphql`
      query {
          inspiration: allWpPost(limit: 3, filter: {categories: {nodes: {elemMatch: {databaseId: {eq: 117}}}}}) {
              nodes {
                  title
                  link
                  uri
                  content
                  databaseId
                  date
                  postsFields {
                      featuredImage {
                          databaseId
                          mediaItemUrl
                      }
                  }
              }
          }
          explore: allWpPost(limit: 3, filter: {categories: {nodes: {elemMatch: {databaseId: {eq: 120}}}}}) {
              nodes {
                  title
                  link
                  uri
                  content
                  databaseId
                  date
                  postsFields {
                      featuredImage {
                          databaseId
                          mediaItemUrl
                      }
                  }
              }
          }
          help: allWpPost(limit: 3, filter: {categories: {nodes: {elemMatch: {databaseId: {eq: 453}}}}}) {
              nodes {
                  title
                  content
                  link
                  uri
                  databaseId
                  date
              }
          }
          tutorials: allWpPost(limit: 3, filter: {categories: {nodes: {elemMatch: {databaseId: {eq: 1}}}}}) {
              nodes {
                  title
                  link
                  uri
                  content
                  databaseId
                  date
                  postsFields {
                      featuredImage {
                          databaseId
                          mediaItemUrl
                      }
                  }
              }
          }
      }
  `);


  const inspiredPostsFromWordpress = inspiredPostsFromWordpressRaw.inspiration.nodes.map(normalizePost);
  const helpPostsFromWordpress = inspiredPostsFromWordpressRaw.help.nodes.map(normalizePost);
  const tutorialsPostsFromWordpress = inspiredPostsFromWordpressRaw.tutorials.nodes.map(normalizePost);
  const explorePostsFromWordpress = inspiredPostsFromWordpressRaw.explore.nodes.map(normalizePost);

  return (
    <Layout location={location}>
      <Section>
        <Breadcrumbs
          items={[{ title: "Viewst", url: "/" }, { title: "Learn", url: "/learn/" }]}
        />
        <PromoBlock />
        <ContentFlow horisontal={[ScreenSize.L, ScreenSize.M]} vertical={[ScreenSize.S]}>
          <>
            <div style={{ marginRight: "30px", paddingTop: "40px" }}>
              <Title color={TITLE_COLORS.White}><FormattedMessage id="pages.learn.sections.main.title"
                                                                  defaultMessage="Learn" /></Title>
              <iframe className="PromoBlock-Video" width="100%" height="100%"
                      src="https://www.youtube.com/embed/wFxcMP_pEig"
                      title="YouTube video player" frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen></iframe>
            </div>
            <div style={{ maxWidth: "350px" }}>
              <SubTitle color={TITLE_COLORS.LightBlue}>
                <FormattedMessage
                  id="pages.learn.sections.main.subtitle"
                  defaultMessage="How to create your first animated HTML banner in 3 minutes and 7 steps"
                />
              </SubTitle>
              <Paragraph fluid>
                <FormattedMessage
                  id="pages.learn.sections.main.description"
                  defaultMessage="How to create a banner? Specs of Ad networks. Design banner tips from the Pros"
                />
              </Paragraph>
            </div>
          </>
        </ContentFlow>
      </Section>
      <TutorialsSection
        title={formatMessage({
          id: "pages.learn.sections.howToStart.title", defaultMessage: "How to start"
        })}
        sectionColor={SECTION_COLOR.LightBlue}
        titleColor={TITLE_COLORS.White}
        seeAllUrl="/tutorials/"
        data={tutorialsPostsFromWordpress}
      />
      <TutorialsSection
        title={formatMessage({
          id: "pages.learn.sections.inspirations.title", defaultMessage: "Inspirations"
        })}
        sectionColor={SECTION_COLOR.White}
        titleColor={TITLE_COLORS.LightBlue}
        seeAllUrl="/get-inspired/"
        data={inspiredPostsFromWordpress}
      />
      {/*inspirationsData[locale]*/}
      <TutorialsSection
        title={formatMessage({
          id: "pages.learn.sections.help.title", defaultMessage: "Help"
        })}
        sectionColor={SECTION_COLOR.DarkGray}
        titleColor={TITLE_COLORS.White}
        seeAllUrl="/help-en/"
        data={helpPostsFromWordpress}
      />
      <TutorialsSection
        title={formatMessage({
          id: "pages.learn.sections.help.title", defaultMessage: "Explore"
        })}
        sectionColor={SECTION_COLOR.White}
        titleColor={TITLE_COLORS.LightBlue}
        seeAllUrl="/explore/"
        data={explorePostsFromWordpress}
      />
      <ContactUsSection />
    </Layout>
  );
};

export default TutorialsPage;
